import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Air Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Air Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative text-center">
                <div
                  className="bg-primary mb-3 mx-auto"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Air Freight</h1>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="img/Air Freight.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    A well-established leader in air freight forwarding,{" "}
                    {companyname} specializes in managing perishable and general
                    cargo shipments. Our reputation is built on delivering
                    efficient and reliable air freight services, with daily
                    cargo handling capabilities connecting India to numerous
                    destinations. Our extensive network provides us with a
                    unique perspective on the challenges that businesses face in
                    the realm of air freight transportation.
                  </p>

                  <p>
                    We distinguish ourselves through our expertise in handling
                    temperature-controlled shipments, including pharmaceuticals,
                    vaccines, bulk medications, fresh produce, flowers,
                    electronics, apparel, and tissue culture products.
                    Recognizing the critical importance of handling perishable
                    goods with precision and care, we have assembled a team of
                    professionals skilled in managing a wide range of perishable
                    items.
                  </p>

                  <p>
                    At {companyname}, we believe that there is no
                    one-size-fits-all solution in air freight because we
                    understand that every client is unique. We offer customized
                    solutions tailored to meet each client's specific
                    requirements. Our team of dedicated experts is committed to
                    providing flexible and innovative air freight solutions that
                    set new industry standards.
                  </p>

                  <p>
                    We provide a comprehensive range of air freight solutions,
                    including domestic air freight and full/part charters.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
