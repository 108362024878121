import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                 About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/About2.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">About Us</h1>
                <p className="mb-4 pb-2">
                  {companyname}, headquartered in Mumbai, is a forward-looking
                  freight forwarding company. Our mission is to provide
                  specialized logistics solutions using cutting-edge technology.{" "}
                  {companyname} specializes in managing perishable goods,
                  time-sensitive express shipments, general cargo,
                  over-dimensional cargo (ODC), hazardous cargo, and
                  refrigerated freight, offering transportation services via
                  air, sea, rail, and road. We are also a certified customs
                  clearance agency.
                </p>
                <div className="row g-4 mb-4 pb-3"></div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src="img/target.png"
                      alt="Icon"
                    />
                  </div>
                  <h1
                    className="display-1 mb-0"
                    style={{ color: "#000000" }}
                  ></h1>
                </div>
                <h5 className="text-white">Our Mission</h5>
                <hr className="w-25" />
                <span>
                  To improve each characteristic that facilitates efficient
                  delivery of goods from one location to another in an effort to
                  maximise client happiness. to deploy the top technologies for
                  operations that have an impact on secure delivery and
                  transportation.
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.3s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src="img/vision.png"
                      alt="Icon"
                    />
                  </div>
                  <h1
                    className="display-1 mb-0"
                    style={{ color: "#000000" }}
                  ></h1>
                </div>
                <h5 className="text-white">Our Vision</h5>
                <hr className="w-25" />
                <span>
                  To establish ourselves as the premier surface transportation
                  firm. To expand our services across the country and expand the
                  capacity of our resources so that they can accommodate
                  projects and client demands of all sizes and varieties.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container feature px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div
              className="col-lg-6 feature-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 ps-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-5">Why Choose Us</h1>
                <p className="mb-4 pb-2">
                  At the core of our company lies the primary motto, "Delivering
                  on time with personalized care and unwavering commitment to
                  total customer satisfaction." We are enthusiastic about
                  providing you with efficient services marked by dedication and
                  dependability. Leveraging our extensive network, dedicated
                  team, and customized solutions, we consistently exceed our
                  clients' expectations. Our network is equipped to handle all
                  your logistical requirements, regardless of your location.
                  Through our strategic partnerships with major carriers, we
                  offer the most cost-effective all-in-one solution in the
                  industry.
                </p>
                <div className="row g-4">
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <div
                        className="btn-square bg-white rounded-circle"
                        style={{ width: "64px", height: "64px" }}
                      >
                        <img
                          className="img-fluid"
                          src="img/checked.png"
                          alt="Icon"
                        />
                      </div>
                      <div className="ms-4">
                        <p className="text-primary mb-2">100% </p>
                        <h5 className="mb-0">Transparency</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <div
                        className="btn-square bg-white rounded-circle"
                        style={{ width: "64px", height: "64px" }}
                      >
                        <img
                          className="img-fluid"
                          src="img/shield.png"
                          alt="Icon"
                        />
                      </div>
                      <div className="ms-4">
                        <p className="text-primary mb-2">Safety</p>
                        <h5 className="mb-0">Is Our Priority</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <div
                        className="btn-square bg-white rounded-circle"
                        style={{ width: "64px", height: "64px" }}
                      >
                        <img
                          className="img-fluid"
                          src="img/group.png"
                          alt="Icon"
                        />
                      </div>
                      <div className="ms-4">
                        <p className="text-primary mb-2">Experienced </p>
                        <h5 className="mb-0">Staff</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <div
                        className="btn-square bg-white rounded-circle"
                        style={{ width: "64px", height: "64px" }}
                      >
                        <img
                          className="img-fluid"
                          src="img/support.png"
                          alt="Icon"
                        />
                      </div>
                      <div className="ms-4">
                        <p className="text-primary mb-2">24/7 Hours</p>
                        <h5 className="mb-0">Support</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pe-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/About 1.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
