import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner " style={{ marginTop: "105px" }}>
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-4 pb-sm-4">
            <h4 className="inner-text-title font-weight-bold pt-sm-5 pt-4">
              Projects & Break Bulk
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Projects & Break Bulk
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative">
                <h3 className="title-style mb-sm-3 mb-2">
                  <span>Projects & Break Bulk</span>
                </h3>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="assets/images/Projects & Break Bulk.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    We excel in the management of project cargo, demanding a
                    high degree of skill, care, and precision. Our expertise
                    extends across various industries, including those involving
                    heavy machinery, oil and gas, construction, energy,
                    maritime, mining, and engines for both shoreside and
                    maritime applications. We have successfully executed
                    projects in challenging, remote locations using specialized
                    solutions and a steadfast determination to facilitate smooth
                    operations.
                  </p>

                  <p>
                    Whether your cargo falls under Break Bulk (BB), Heavy Lift
                    (HL), or Out of Gauge (OOG) categories, we have the
                    capabilities to handle complex transport operations
                    efficiently. Choose {companyname} Logistics as your
                    trusted partner to oversee every aspect of your projects,
                    from initiation to completion.
                  </p>

                  <p>
                    Empower your business aspirations and plans by partnering
                    with us, where our unwavering commitment transforms your
                    visions into reality.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
