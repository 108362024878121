import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      {/* Carousel Start */}
      <div className="container-fluid p-0 pb-5 w3-banner jarallax">
        <video autoPlay loop muted>
          <source src="img/video.mp4"></source>
        </video>
      </div>
      {/* Carousel End */}
      {/* Facts Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src="img/target.png"
                      alt="Icon"
                    />
                  </div>
                  <h1
                    className="display-1 mb-0"
                    style={{ color: "#000000" }}
                  ></h1>
                </div>
                <h5 className="text-white">Our Mission</h5>
                <hr className="w-25" />
                <span>
                  To improve each characteristic that facilitates efficient
                  delivery of goods from one location to another in an effort to
                  maximise client happiness. to deploy the top technologies for
                  operations that have an impact on secure delivery and
                  transportation.
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 wow fadeIn" data-wow-delay="0.3s">
              <div className="h-100 bg-dark p-4 p-xl-5">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div
                    className="btn-square rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#000000",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src="img/vision.png"
                      alt="Icon"
                    />
                  </div>
                  <h1
                    className="display-1 mb-0"
                    style={{ color: "#000000" }}
                  ></h1>
                </div>
                <h5 className="text-white">Our Vision</h5>
                <hr className="w-25" />
                <span>
                  To establish ourselves as the premier surface transportation
                  firm. To expand our services across the country and expand the
                  capacity of our resources so that they can accommodate
                  projects and client demands of all sizes and varieties.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Facts Start */}
      {/* About Start */}
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/About2.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">About Us</h1>
                <p className="mb-4 pb-2">
                  {companyname}, headquartered in Mumbai, is a forward-looking
                  freight forwarding company. Our mission is to provide
                  specialized logistics solutions using cutting-edge technology.{" "}
                  {companyname} specializes in managing perishable goods,
                  time-sensitive express shipments, general cargo,
                  over-dimensional cargo (ODC), hazardous cargo, and
                  refrigerated freight, offering transportation services via
                  air, sea, rail, and road. We are also a certified customs
                  clearance agency.
                </p>
                <div className="row g-4 mb-4 pb-3"></div>
                <Link
                  to="/About"
                  className="btn btn-primary rounded-pill py-3 px-5"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Projects Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-primary mb-3 mx-auto"
              style={{ width: "60px", height: "2px" }}
            />
            <h1 className="display-5 mb-5">Our Solutions</h1>
          </div>

          <div className="row g-4 portfolio-container">
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Air Freight.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Air Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Air Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Air"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Sea Freight.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Sea Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Sea Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Sea"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Road Freight.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Road Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Road Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Road"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Rail Freight.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Rail Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Rail Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Rail"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src="img/Courier.jpg" alt="" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Courier</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Courier</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Courier"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Warehousing Services.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Warehousing Services</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Warehousing Services</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Warehousing"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Projects End */}

      {/* Feature Start */}
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container feature px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div
              className="col-lg-6 feature-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 ps-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-5">Why Choose Us</h1>
                <p className="mb-4 pb-2">
                  At the core of our company lies the primary motto, "Delivering
                  on time with personalized care and unwavering commitment to
                  total customer satisfaction." We are enthusiastic about
                  providing you with efficient services marked by dedication and
                  dependability. Leveraging our extensive network, dedicated
                  team, and customized solutions, we consistently exceed our
                  clients' expectations. Our network is equipped to handle all
                  your logistical requirements, regardless of your location.
                  Through our strategic partnerships with major carriers, we
                  offer the most cost-effective all-in-one solution in the
                  industry.
                </p>
                <div className="row g-4">
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <div
                        className="btn-square bg-white rounded-circle"
                        style={{ width: "64px", height: "64px" }}
                      >
                        <img
                          className="img-fluid"
                          src="img/checked.png"
                          alt="Icon"
                        />
                      </div>
                      <div className="ms-4">
                        <p className="text-primary mb-2">100% </p>
                        <h5 className="mb-0">Transparency</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <div
                        className="btn-square bg-white rounded-circle"
                        style={{ width: "64px", height: "64px" }}
                      >
                        <img
                          className="img-fluid"
                          src="img/shield.png"
                          alt="Icon"
                        />
                      </div>
                      <div className="ms-4">
                        <p className="text-primary mb-2">Safety</p>
                        <h5 className="mb-0">Is Our Priority</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <div
                        className="btn-square bg-white rounded-circle"
                        style={{ width: "64px", height: "64px" }}
                      >
                        <img
                          className="img-fluid"
                          src="img/group.png"
                          alt="Icon"
                        />
                      </div>
                      <div className="ms-4">
                        <p className="text-primary mb-2">Experienced </p>
                        <h5 className="mb-0">Staff</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <div
                        className="btn-square bg-white rounded-circle"
                        style={{ width: "64px", height: "64px" }}
                      >
                        <img
                          className="img-fluid"
                          src="img/support.png"
                          alt="Icon"
                        />
                      </div>
                      <div className="ms-4">
                        <p className="text-primary mb-2">24/7 Hours</p>
                        <h5 className="mb-0">Support</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pe-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/About 1.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature End */}

      {/* Quote Start */}
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container quote px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/banner.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 quote-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-5">Quick Quote</h1>
                <p className="mb-4 pb-2">
                  It's time to simplify your shipping process. Submit a request
                  through our Freight Forwarding Get Quote form and experience
                  the difference.
                </p>
                <form
                  action="/php/getquoteHome.php"
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input
                        className="form-control border-0 p-4"
                        value={uname}
                        onChange={(event) => handleUnameChange(event)}
                        type="text"
                        name="uname"
                        id="uname"
                        placeholder="Enter your Fullname"
                        required="Please Enter the Fullname"
                      />{" "}
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        className="form-control border-0 p-4"
                        value={uemail}
                        onChange={(event) => handleUemailChange(event)}
                        type="email"
                        name="uemail"
                        id="uemail"
                        placeholder="Enter Your Email"
                        required="Please Enter Your Email"
                      />{" "}
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        className="form-control border-0 p-4"
                        value={uphone}
                        onChange={(event) => handleUphoneChange(event)}
                        type="text"
                        name="uphone"
                        id="uphone"
                        placeholder="Enter Phone Number"
                        required="Please Enter Phone Number"
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <select
                        className="form-select border-0 px-4"
                        name="uservice"
                        id="uservice"
                        value={uservice}
                        onChange={(event) => handleuserviceChange(event)}
                      >
                        <option selected>Service Required</option>
                        <option value="Air Freight Forwarding">
                          Air Freight Forwarding
                        </option>
                        <option value="Ocean Freight Forwarding">
                          Sea Freight Forwarding
                        </option>
                        <option value="Land Freight Forwarding">
                          Land Freight Forwarding
                        </option>
                      </select>
                    </div>

                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Get A Free Quote
                      </button>
                      <h5 style={{ color: "red" }}>{result}</h5>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Quote End */}
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-primary mb-3 mx-auto"
              style={{ width: "60px", height: "2px" }}
            />
            <h1 className="display-5 mb-5">Our Industries</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img className="img-fluid" src="img/Automotive.jpg" alt="" />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Automotive</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/Consumer Goods.jpg"
                    alt=""
                  />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Consumer Goods</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img className="img-fluid" src="img/Mineral Oil.jpg" alt="" />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Mineral Oil</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img className="img-fluid" src="img/Wood.jpg" alt="" />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Wood</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img className="img-fluid" src="img/Chemicals.jpg" alt="" />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Chemicals</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/Raw Materials.jpg"
                    alt=""
                  />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Raw Materials</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}
