import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid bg-dark text-secondary footer mt-5 py-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Address</h5>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3" />
                {companyaddress}
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                {companynumber}
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3" />
                {companyemail}
              </p>
            </div>
            <div className="col-lg-2 col-md-6">
              <h5 className="text-light mb-4">Services</h5>
              <Link to="/Air" className="btn btn-link">
                Air Freight
              </Link>
              <Link to="/Sea" className="btn btn-link">
                Sea Freight
              </Link>
              <Link to="/Road" className="btn btn-link">
                Road Freight
              </Link>
              <Link to="/Rail" className="btn btn-link">
                Rail Freight
              </Link>
              <Link to="/Courier" className="btn btn-link">
                Courier
              </Link>
              <Link to="/Warehousing" className="btn btn-link">
                Warehousing Services
              </Link>
            </div>
            <div className="col-lg-2 col-md-6">
              <h5 className="text-light mb-4">Quick Links</h5>
              <Link className="btn btn-link" to="/Home">
                Home
              </Link>
              <Link className="btn btn-link" to="/About">
                About Us
              </Link>
              <Link className="btn btn-link" to="/Whyus">
                Why Choose Us
              </Link>
              <Link className="btn btn-link" to="/Services">
                Services
              </Link>
              <Link className="btn btn-link" to="/Industries">
                Industries
              </Link>
              <Link className="btn btn-link" to="/Contact">
                Contact Us
              </Link>
              <Link className="btn btn-link" to="/Getquote">
                Request Quote
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Newsletter</h5>
              <p>
                Your logistics success story starts here. Subscribe to our
                newsletter for expert advice, industry news, and tailored
                solutions
              </p>
              <div className="position-relative w-100">
                <form
                  className="subscribe d-flex"
                  action="/php/thankyou-subscribe.php "
                  method="POST"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <input
                    onChange={(event) => handleSubemailChange(event)}
                    id="subemail"
                    value={subemail}
                    className="form-control bg-transparent border-secondary w-100 py-3 ps-4 pe-5"
                    type="email"
                    name="subemail"
                    style={{ padding: "30px" }}
                    placeholder="Enter your email "
                    required
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                    >
                      Sign Up
                    </button>
                  </div>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
      {/* Copyright Start */}
      <div className="container-fluid py-4" style={{ background: "#000000" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©2023{" "}
              <Link className="border-bottom" to="/">
                {companyname}
              </Link>
              , All Right Reserved.
            </div>
            <div className="col-md-4 text-center text-md-end">
              <Link className="border-bottom" to="/Privacy">
                Privacy Policy
              </Link>
            </div>
            <div className="col-md-2 text-center text-md-end">
              <Link className="border-bottom" to="/Terms">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright End */}
    </>
  );
};

export default Footer;
