import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Contact
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Contact
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Contact Start */}
      <div className="container-fluid bg-light overflow-hidden px-lg-0">
        <div className="container contact px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div
              className="col-lg-6 contact-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 ps-lg-0">
                <div className="section-title text-start">
                  <h1 className="display-5 mb-4">Contact Us</h1>
                </div>
                <p className="mb-4">
                  Why wait? Take control of your shipments and streamline your
                  logistics process. Fill in our freight forwarding contact
                  form, and let's get started.
                </p>
                <form
                  id="contactForm"
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          className="form-control border-0 p-4"
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          required
                        />
                        <p class="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          className="form-control border-0 p-4"
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                          required
                        />
                        <p class="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          className="form-control border-0 p-4"
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                          required
                        />
                        <p class="help-block text-danger"></p>
                      </div>
                    </div>{" "}
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          className="form-control border-0 p-4"
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                          required
                        />
                        <p class="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <textarea
                          className="form-control border-0 p-4"
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                        <p class="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary py-3 px-5"
                        type="submit"
                        defaultValue=""
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
              </div>
            </div>
            <div className="col-lg-6 pe-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100 pt-4">
                <iframe
                  className="position-absolute w-100 h-100"
                  style={{ objectFit: "cover" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.2768225293917!2d73.02876397196978!3d19.007519714764744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3075bf5c591%3A0x63aebebcd2cca5fe!2sPlot%20No%2047%2C%20Belle%20Vista%2C%20Sector%2015%2C%20CBD%20Belapur%2C%20Navi%20Mumbai%2C%20Maharashtra%20400614!5e0!3m2!1sen!2sin!4v1695966612009!5m2!1sen!2sin"
                  frameBorder={0}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}
      <Footer />
    </>
  );
}
