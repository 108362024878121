import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Rail Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Rail Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative text-center">
                <div
                  className="bg-primary mb-3 mx-auto"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Rail Freight</h1>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="img/Rail Freight.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    {companyname}, a leading freight forwarding company,
                    specializes in delivering customized logistics solutions
                    tailored to businesses of all sizes. In addition to our
                    renowned air and ocean freight services, we offer rail
                    transport options to better meet the diverse needs of our
                    clients.
                  </p>

                  <p>
                    Rail transportation plays a pivotal role in the movement of
                    goods within India, offering an economical and
                    environmentally responsible means of transporting large
                    quantities of cargo across vast distances. The dedicated
                    team of professionals at {companyname} possesses extensive
                    expertise in rail transportation, providing comprehensive
                    solutions that encompass door-to-door delivery, cargo
                    handling, storage, customs clearance, and documentation.
                  </p>

                  <p>
                    Our extensive rail transportation network connects all major
                    cities and industrial hubs throughout India, allowing us to
                    offer a range of rail freight services, including
                    cross-border operations, less-than-truckload (LTL) and full
                    truckload (TL) freight, and parcel delivery. Regardless of
                    shipment volume or complexity, our rail transportation
                    solutions are tailored to meet each client's specific
                    requirements.
                  </p>

                  <p>
                    Opting for rail freight movements can be a cost-effective
                    solution for the transportation of your bulk cargo.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
