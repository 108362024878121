import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Road Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Road Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative text-center">
                <div
                  className="bg-primary mb-3 mx-auto"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Road Freight</h1>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="img/Road Freight.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    {companyname} offers standardized surface transportation
                    services with a strong commitment to the safe handling of
                    cargo. Our primary objective is to ensure the delivery of
                    goods in perfect condition, thereby ensuring complete client
                    satisfaction. We pride ourselves on providing our clients
                    with the utmost integrity and dedication, resulting in
                    efficient and reliable services.
                  </p>

                  <p>
                    Our road transport services are highly esteemed by our
                    clients, and we frequently receive commendations for our
                    prompt responsiveness to their needs. We exclusively employ
                    top-tier vehicles for the transportation of goods from one
                    location to another, solidifying our reputation as one of
                    India's premier road transport service providers.
                  </p>

                  <p>
                    Our land transport service standards are thoughtfully
                    aligned with the evolving demands of businesses and
                    industries. Our clients benefit from the security our
                    services offer, including precisely defined transit times
                    that enable financial planning and precise control over the
                    flow of goods.
                  </p>

                  <p>
                    Specializing in the safe transportation of our clients'
                    goods, we rely on our own fleet of container trailers to
                    ensure complete safety and prompt delivery. We operate under
                    the principles of care and perfection, with the ultimate
                    goal of delivering goods in impeccable condition. Our
                    commitment to being a customer-centric company sets us
                    apart, with a steadfast focus on on-time delivery and
                    safety. We have a track record of delivering reliable,
                    efficient, and professional services to our clients.
                  </p>

                  <p>
                    Choose road transportation for your regular and last-mile
                    delivery needs!
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
