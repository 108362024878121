import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Warehousing Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Warehousing Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative text-center">
                <div
                  className="bg-primary mb-3 mx-auto"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Warehousing Services</h1>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="img/Warehousing Services.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    In the fast-paced business landscape of today, large
                    organizations often have more products in various stages of
                    production than they can readily accommodate. {companyname}{" "}
                    offers a range of solutions designed to help you save both
                    time and space by providing efficient warehouse management.
                    Our comprehensive services encompass intracity and intercity
                    logistics as well as warehouse and domestic courier
                    services. At the core of our logistics management system
                    lies our robust warehouse management.
                  </p>

                  <p>
                    As one of the well-established warehousing firms,{" "}
                    {companyname} caters to both import and export shipments and
                    collaborates with partners offering specialized solutions
                    for temperature-controlled goods. We excel in repacking and
                    palletizing shipments and also maintain meticulous control
                    over inventories.
                  </p>

                  <p>
                    Our logistics warehouse is designed to meet the ever-growing
                    needs of your business, providing a reliable and efficient
                    solution for your storage and inventory management
                    requirements.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
