import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Sea Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Sea Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative text-center">
                <div
                  className="bg-primary mb-3 mx-auto"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Sea Freight</h1>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="img/Sea Freight.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    As a prominent leader in the ocean freight industry,{" "}
                    {companyname} operates in major seaports, specializing in
                    delivering tailored maritime freight solutions that drive
                    our clients' financial success. Leveraging our exceptional
                    agency network and skilled workforce, we provide seamless
                    transportation and diverse solutions customized to meet the
                    unique needs of each client.
                  </p>

                  <p>
                    One of our key strengths lies in our expertise in handling
                    breakbulk, over-dimensional, and heavy-lift cargoes.
                    Recognizing the individuality of every shipment and the
                    importance of careful handling, we offer specialized
                    solutions to ensure the prompt and secure delivery of your
                    cargo. Our experienced team is well-equipped to address any
                    challenges that may arise during the shipping process.
                  </p>

                  <p>
                    With an extensive agency network covering major seaports
                    worldwide and strategic partnerships with leading carriers
                    and shipping lines, we offer a range of dependable,
                    flexible, and cost-effective ocean freight services. Our
                    expertise spans various cargo types, including
                    full-container-load (FCL), less-than-container-load (LCL),
                    and breakbulk shipments.
                  </p>

                  <p>
                    Unlock the full potential of your sea freight with our
                    diverse and comprehensive solutions!
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
