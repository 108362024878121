import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Industries
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Industries
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/Automotive.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Automotive</h1>
                <p className="mb-4 pb-2">
                  <p>
                    Our logistics solutions are finely tuned to meet the demands
                    of the automotive industry, whether it's for raw materials,
                    semi-finished products, or fully assembled vehicles.
                    Punctuality is our hallmark, ensuring the continuous rhythm
                    of production processes. This precision is crucial for
                    maintaining the seamless flow of automotive manufacturing.
                    To enhance our tailored services, we utilize cutting-edge
                    special wagons designed to cater to the specific
                    requirements of each product, showcasing our commitment to
                    delivering customized solutions across the automotive supply
                    chain. With each journey, we uphold our dedication to
                    precision, innovation, and excellence in servicing the
                    automotive industry's logistics needs.
                  </p>
                </p>
                <div className="row g-4 mb-4 pb-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Consumer Goods</h1>
                <p className="mb-4 pb-2">
                  <p>
                    Our commitment to freshness, timeliness, and
                    eco-friendliness extends to various industries, including
                    foodstuffs, beverages, household appliances, pet food, and
                    furniture. We take pride in ensuring that these goods reach
                    our customers' warehouses seamlessly. Our capabilities are
                    attested by impressive statistics: a daily transportation
                    volume of one million liters of beverages and an annual
                    movement of two million domestic appliances. These numbers
                    exemplify our prowess and proficiency in meeting the
                    logistics and transportation requisites of prominent
                    international manufacturers and retail chains. Our focus
                    remains on reliability, adherence to schedules, and
                    establishing stable logistics processes to support the
                    success of food and beverage manufacturers, retailers, and
                    appliance makers.
                  </p>
                </p>
                <div className="row g-4 mb-4 pb-3"></div>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/Consumer Goods.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/Mineral Oil.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Mineral Oil</h1>
                <p className="mb-4 pb-2">
                  <p>
                    We recognize the significance of demand-driven provision of
                    raw oil to the triumph of oil and gas enterprises. Our
                    integrated logistics solutions for the oil industry
                    encompass professional tank wagon management, calibrated
                    fuel depot provisioning based on demand, and precise
                    orchestration of shipments. This framework caters to the
                    intricate dynamics of the sector, ensuring precision and
                    care in navigating the journey of oil from transshipment
                    ports to refineries and fuel depots.
                  </p>
                </p>
                <div className="row g-4 mb-4 pb-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Wood</h1>
                <p className="mb-4 pb-2">
                  <p>
                    Our dedicated team of experts adeptly handles international
                    material shipments in the timber industry. Our holistic
                    approach covers every aspect of the journey, ensuring a
                    seamless and efficient process from start to finish. With
                    us, you gain the advantage of accessing a unified resource
                    that skillfully navigates the complexities of the timber
                    industry's supply chain. This commitment to providing
                    integrated solutions from a singular source simplifies
                    logistics, optimizes processes, and enhances material
                    transport efficiency, ultimately contributing to the
                    sustained success of the timber sector.
                  </p>
                </p>
                <div className="row g-4 mb-4 pb-3"></div>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/Wood.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/Chemicals.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Chemicals</h1>
                <p className="mb-4 pb-2">
                  <p>
                    Safe and secure transportation of hazardous materials via
                    rail is our specialty. We craft integrated logistics
                    concepts tailored for chemical products, showcasing our
                    expertise in safety measures and innovative problem-solving.
                    Transporting hazardous materials necessitates meticulous
                    attention to detail, and our comprehensive and tailored
                    service for the transportation of hazardous materials is
                    naturally ISO-certified, reflecting our unwavering
                    commitment to quality and safety.
                  </p>
                </p>
                <div className="row g-4 mb-4 pb-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div
                  className="bg-primary mb-3"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Raw Materials</h1>
                <p className="mb-4 pb-2">
                  We play a crucial role in supporting the global industrial
                  machinery by ensuring the timely and top-notch provisioning of
                  raw materials. These materials are sourced either directly
                  from mining operations or imported through harbors. Our
                  logistics solutions mirror the dynamic nature of the raw
                  materials industry, showcasing the same level of flexibility
                  and innovation that characterizes this ever-evolving sector.
                  Whether the raw materials originate deep within mining
                  operations or are imported through harbors, our logistical
                  expertise is finely tuned to meet the multifaceted demands of
                  this industry. Our solutions are not only versatile but also
                  innovative, perfectly aligned with the ever-changing landscape
                  of the raw materials sector.
                </p>
                <div className="row g-4 mb-4 pb-3"></div>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/Raw Materials.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
