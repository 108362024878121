import { Outlet, Link } from "react-router-dom";
import Getquote from "../pages/Getquote";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = (
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
) => {
  return (
    <>
 {/* Topbar Start */}
 <div className="container-fluid bg-dark px-5">
          <div className="row gx-4 d-none d-lg-flex">
            <div className="col-lg-4 text-start">
              <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                <div className="btn-sm-square rounded-circle bg-primary me-2">
                  <small className="fa fa-map-marker-alt text-white" />
                </div>
                <small>Plot No – 47, Sec – 30A, Washi 400703</small>
              </div>
             
            </div>
            <div className="col-lg-4 text-start">
             
              <div className="h-100 d-inline-flex align-items-center py-3">
                <div className="btn-sm-square rounded-circle bg-primary me-2">
                  <small className="fa fa-envelope-open text-white" />
                </div>
                <small>{companyemail}</small>
              </div>
            </div>
            <div className="col-lg-4 text-end">
              <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                <div className="btn-sm-square rounded-circle bg-primary me-2">
                  <small className="fa fa-phone-alt text-white" />
                </div>
                <small>{companynumber}</small>
              </div>
             
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5" style={{height:'100px'}}>
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <img src="img/logo.png" style={{height:"90px"}}></img>
          </Link>
          <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-4 py-lg-0">
              <Link to="/Home" className="nav-item nav-link">Home</Link>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Our Solutions</Link>
                <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                  <Link to="/Air" className="dropdown-item">Air Freight</Link>
                  <Link to="/Sea" className="dropdown-item">Sea Freight</Link>
                  <Link to="/Road" className="dropdown-item">Road Freight</Link>
                  <Link to="/Rail" className="dropdown-item">Rail Freight</Link>
                  <Link to="/Courier" className="dropdown-item">Courier</Link>
                  <Link to="/Warehousing" className="dropdown-item">Warehousing Services</Link>
                  
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Industries</Link>
                <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                  <Link to="/Industries" className="dropdown-item">Automotive</Link>
                  <Link to="/Industries" className="dropdown-item">Consumer Goods</Link>
                  <Link to="/Industries" className="dropdown-item">Mineral Oil</Link>
                  <Link to="/Industries" className="dropdown-item">Wood</Link>
                  <Link to="/Industries" className="dropdown-item">Chemicals</Link>
                  <Link to="/Industries" className="dropdown-item">Raw Materials</Link>

                  
                </div>
              </div><Link to="/Whyus" className="nav-item nav-link">Why {companyname}</Link>
              <Link to="/About" className="nav-item nav-link">Company Profile</Link>
              
              
              <Link to="/Getquote" className="nav-item nav-link">Request Quote</Link>
            </div>
            <div className="h-100 d-lg-inline-flex align-items-center d-none">
            <Link to="/Contact" className="btn btn-primary rounded-pill py-3 px-5">
                  Contact Us
                </Link> </div>
          </div>
        </nav>
        {/* Navbar End */}
    </>
  );
};

export default Header;
