import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Air from "./pages/Air";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Whyus from "./pages/Whyus";
import Projects from "./pages/Projects";
import Warehousing from "./pages/Warehousing";
import Industries from "./pages/Industries";
import Courier from "./pages/Courier";
import Road from "./pages/Road";
import Careers from "./pages/Careers";


import Rail from "./pages/Rail";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Whyus" element={<Whyus />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Rail" element={<Rail />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Industries" element={<Industries />} />
          <Route path="/Courier" element={<Courier />} />
          <Route path="/Road" element={<Road />} />

    
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
